<template>
  <div>
    <b-modal size="lg" v-model="popup">
      <Popup v-if="dataPopup" :dataList="dataPopup" @close="onclosePopup">
      </Popup>
    </b-modal>
    <div style="min-height: 100vh">
      <Parallax
        tag="Banner"
        imgHeight="100vh"
        :opacity="0.4"
        :fontSize="banner"
        :state="`${getState}`"
      />
    </div>
    <div ref="Annoucement" style="position: absolute; bottom: 0px; width: 100%">
      <Annoucement @loaded="test" @update="oncloseAnnoucement" />
    </div>
    <div class="relative" :id="tepaSection.id">
      <Section
        :idSection="tepaSection.id"
        v-if="tepaSection.section"
        :dataList="tepaSection.section"
        :style="`padding-top: ${
          this.screenSize <= 992 ? 40 : 80
        }px;padding-bottom:${this.screenSize <= 992 ? 50 : 100}px`"
      />
    </div>
    <div>
      <Parallax
        v-if="parallax"
        :imgHeight="`${parallaxSize.height}px`"
        :img="parallax"
        :fontSize="parallaxSize.size"
      />
      <div :id="sectionExp" class="relative">
        <Section
          :idSection="sectionExp"
          v-if="sectionData"
          :dataList="sectionData"
          :style="`padding-top: ${screenSize <= 992 ? 40 : 80}px`"
        />
        <div v-for="(item, index) in sectionData" :key="index">
          <b-row
            class="justify-content-center text-center"
            :style="`padding: 40px 0 ${
              screenSize <= 992 ? 40 : 100
            }px;margin: 0px;`"
          >
            <b-col cols="11" lg="10">
              <b-row>
                <b-col
                  v-for="(arr, index) in item.items"
                  :id="`menu-${index}`"
                  :key="arr.img.url"
                  col
                  class="img-dinning invisible"
                  lg="4"
                  md="6"
                  cols="6"
                  style="overflow: hidden; padding: 10px"
                >
                  <div style="overflow: hidden">
                    <b-img :src="arr.img.url" class="dinner-img" />
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div>
      <Parallax
        v-if="gardenWing.parallax.img"
        :img="gardenWing.parallax.img"
        :text="gardenWing.parallax.text"
        :imgHeight="`${parallaxSize.height}px`"
        :fontSize="parallaxSize.size"
        fontWeight="500"
      />
      <div :id="gardenWing.id">
        <component
          v-show="item.primary.show"
          :is="item.primary.type"
          v-for="(item, index) in gardenWing.section"
          :key="index"
          :dataList="item.primary"
          :styleCard="`${
            screenSize >= 992 ? 'padding:90px 0' : 'margin:40px 0 0px'
          }`"
          :col="11"
          :imgHeight="580"
        />
      </div>
    </div>
    <div>
      <Parallax
        v-if="tepaGarden.parallax.img"
        :img="tepaGarden.parallax.img"
        :text="tepaGarden.parallax.text"
        :imgHeight="`${parallaxSize.height}px`"
        :fontSize="parallaxSize.size"
        fontWeight="500"
      />
      <div
        class="relative"
        :id="tepaGarden.id"
        :style="`${
          screenSize >= 992 ? 'padding-top: 80px' : 'padding-top: 40px'
        }`"
      >
        <component
          :is="item.primary.type"
          :idSection="tepaGarden.id"
          v-for="(item, index) in tepaGarden.section"
          :key="index"
          :styleCard="`${
            screenSize >= 992 ? 'margin:70px 0 0px' : 'margin:40px 0 0px'
          }`"
          :dataList="item.primary"
          :reverse="true"
          :col="12"
          :imgHeight="500"
        />
      </div>
    </div>
    <Reservation />
    <Contacts />
  </div>
</template>

<script>
import Popup from "@/components/Popup";
import $ from "jquery";
export default {
  components: {
    Annoucement: () => import(`@/components/Annoucement`),
    Section: () => import(`@/components/Section`),
    Parallax: () => import(`@/components/Parallax`),
    CardInformationFull: () => import(`@/components/CardInformationFull`),
    CardInformationStack: () => import(`@/components/CardInformationStack`),
    Reservation: () => import(`@/components/Reservation`),
    Contacts: () => import(`@/components/Contacts`),
    Popup,
  },
  data() {
    return {
      img: [],
      parallax: null,
      screenSize: null,
      state: null,
      stateAnnoucement: true,
      dataPopup: null,
      tepaSection: {
        id: null,
        section: null,
      },
      sectionData: null,
      sectionTepa: null,
      sectionExp: null,
      gardenWing: {
        parallax: {
          img: null,
          text: null,
        },
        section: null,
        id: null,
      },
      tepaGarden: {
        parallax: {
          img: null,
          text: null,
        },
        section: null,
        id: null,
      },
    };
  },
  computed: {
    ref() {
      return this.$store.getters["cms/ref"];
    },
    popup() {
      return this.$store.getters["cms/popup"];
    },
    banner() {
      return this.screenSize >= 992 ? 68 : 30;
    },
    parallaxSize() {
      return {
        height: this.screenSize >= 992 ? 580 : 250,
        size: this.screenSize >= 992 ? 50 : 24,
      };
    },
    getState() {
      return this.state;
    },
  },
  watch: {
    stateAnnoucement() {
      console.log(this.stateAnnoucement);
      if (!this.stateAnnoucement) {
        this.state = "unset";
      }
    },
    $route() {
      console.log(this.$route);
      const id = this.$route.query.id;
      if ($(`#${id}`).offset()) {
        $("html, body").animate(
          { scrollTop: $(`#${id}`).offset().top - 70 },
          1000
        );
      }
    },
  },
  created() {
    this.getPopup();
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    $(document).scroll(this.setAnimation);
    this.getBaanTepaSection();
    this.getBaanTepaDinning();
    this.getGardenWing();
    this.getTepaGarden();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    oncloseAnnoucement() {
      this.stateAnnoucement = false;
    },
    setAnimation() {
      const me = this;
      const height = $(window).scrollTop() + $(window).height();
      me.sectionData[0].items.forEach((_, index) => {
        if ($(`#menu-${index}`).length > 0) {
          if (height > $(`#menu-${index}`).offset().top) {
            $(`#menu-${index}`)
              .addClass("animate__animated animate__fadeInUp")
              .removeClass("invisible");
          }
        }
      });
    },
    test() {
      this.$nextTick(() => {
        const alertHeight = this.$refs.Annoucement.getBoundingClientRect()
          .height;
        console.log(alertHeight);
        if (alertHeight === 0) {
          this.state = "unset";
        } else {
          this.state = alertHeight + "px";
        }
      });
    },
    async getBaanTepaSection() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["BaanTepaSection"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        this.tepaSection.id = data.id[0].text;
        this.tepaSection.section = data.body;
      }
    },
    async getBaanTepaDinning() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["BaanTepaDining"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        this.sectionExp = data.id[0].text;
        this.parallax = data.palrallax.url;
        this.sectionData = data.body;
        console.log(this.sectionData);
      }
    },
    async getGardenWing() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["BaanTepaGardenWing"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        this.gardenWing.id = data.id[0].text;
        this.gardenWing.parallax.img = data.parallax.url;
        this.gardenWing.parallax.text =
          data.text.length > 0 ? data.text[0].text : null;
        this.gardenWing.section = data.body;
      }
    },
    async getTepaGarden() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["BaanTepaGardenCafe"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        const data = res.data.results[0].data;
        this.tepaGarden.id = data.id[0].text;
        this.tepaGarden.parallax.img = data.parallax.url;
        this.tepaGarden.parallax.text =
          data.text.length > 0 ? data.text.length[0].text : null;
        this.tepaGarden.section = data.body;
        console.log(data.body);
      }
    },
    async getPopup() {
      const params = {
        ref: this.ref,
        q: `[[at(document.tags, ["Popup"])]]`,
      };
      const res = await this.$http.get(
        process.env.VUE_APP_URL + "/api/v2/documents/search",
        { params }
      );
      if (res.status === 200) {
        await this.$store.dispatch(
          "cms/setPopup",
          res.data.results[0].data.popup // true, false
        );
        this.dataPopup = res.data.results[0].data;
      }
    },
    onResize() {
      this.screenSize =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    },
    async onclosePopup() {
      await this.$store.dispatch("cms/setPopup", false);
    },
  },
};
</script>
<style>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 30%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
<style scoped>
/* .relative {
  position: relative;
  background-color: rgb(255, 255, 255);
} */
.dinner-img {
  width: 100%;
  /* padding: 10px; */
}
.dinner-img:hover {
  transform: scale(1.1);
  overflow: hidden;
  -webkit-transition: all 0.8s ease !important;
  -moz-transition: all 0.8s ease !important;
  -ms-transition: all 0.8s ease !important;
  transition: all 0.8s ease !important;
}
>>> .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.1);
}
>>> .modal-dialog {
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}
>>> .modal-body {
  padding: 0px;
  margin: 40px 0;
}
>>> .modal-content {
  height: auto;
  /* min-height: 80%; */
  width: 88%;
  border-radius: 0;
  background-color: rgba(255, 255, 255, 1);
}
>>> .modal-header {
  display: none;
}
>>> .modal-footer {
  display: none;
}
@media only screen and (max-width: 992px) {
  >>> .modal-content {
    padding: 10px 0;
  }
  .dinner-img {
    /* padding: 7px; */
  }
  >>> .modal-body {
    margin: 0px;
  }
}
</style>
