<template>
  <div class="popup-size">
    <b-row align-h="center" align-v="center" style="height: 100%; margin: 0px">
      <b-col cols="11" lg="10" class="text-right pb-3 pr-lg-0 pr-xl-0">
        <span class="close-text" @click="onclosePopup">Enter Website</span>
        <img
          @click="onclosePopup"
          :src="require(`@/assets/close-icon.svg`)"
          style="cursor: pointer"
        />
      </b-col>
      <b-col
        cols="11"
        lg="10"
        class="text-right pb-3 pr-lg-0 pr-xl-0"
        style="padding: 0; height: 100%"
      >
        <div class="popup-content">
          <div class="popup-text">
            <h2>
              <span class="border-red tepa-h2">
                {{ dataList.header[0].text }}
              </span>
            </h2>
            <div class="space-tepa-p">
              <prismic-rich-text class="tepa-p" :field="dataList.info" />
            </div>
            <b-button
              class="btn-reservation"
              squared
              @click="toPage(dataList.link[0].text)"
              ><span class="label">
                {{ dataList.label[0].text }}</span
              ></b-button
            >
          </div>
          <div class="popup-img">
            <div
              class="img-size"
              :style="{
                backgroundImage: `url(${dataList.img.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }"
            ></div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["dataList"],
  mounted() {
    console.log(this.dataList);
  },
  methods: {
    onclosePopup() {
      this.$emit("close");
    },
    toPage(url) {
      if (url.startsWith("http")) {
        let win = window.open(url, "_blank");
        win.focus();
      } else {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style scoped>
.img-size {
  height: 492px;
  width: 100%;
}
.tepa-h2 {
  padding-bottom: 15px;
  display: inline-block;
  border-bottom: 2px solid;
  text-align: center;
}
.popup-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.popup-img {
  height: 100%;
  width: 100%;
}
.popup-text {
  text-align: center;
  width: 80%;
  max-width: 800px;
  padding-right: 35px;
}
.close-text {
  margin-right: 15px;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  align-items: center;
  text-align: center;
  text-decoration-line: underline;
}
.space-tepa-p {
  max-height: 360px;
  overflow: auto;
  /* box-shadow: inset 0px -7px 6px -8px rgb(32 33 36 / 28%); */
}
.popup-size {
  height: 100%;
  /* position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  background-color: white; */
}
.btn-reservation {
  background: #740b1b;
  font-weight: 600;
  font-size: 21px;
  margin-top: 10px;
  width: 267px;
  height: 47px;
}
@media only screen and (max-width: 992px) {
  .btn-reservation {
    margin-top: 0px;
    margin-bottom: 10px;
    background: #740b1b;
    font-weight: 600;
    font-size: 18px;
    width: 100%;
    height: 47px;
  }
  .popup-content {
    display: flex;
    flex-direction: column;
  }
  .img-size {
    height: 150px;
    width: 100%;
  }
  .popup-text {
    text-align: center;
    width: 100%;
    padding-right: 0px;
  }
  .space-tepa-p {
    max-height: 230px;
    overflow: auto;
  }
}
</style>